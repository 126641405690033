import styled from '@emotion/styled'
import { useContext } from 'react'
import { Link } from 'react-router-dom'
import logo from '../../assets/images/austria-codex-online.svg'
import msdLogo from '../../assets/images/msd-logo.svg'
import { ContextContext } from '../../contexts/context'
import { Paths } from '../../utilities/path.constants'
import { Box } from '../Layout/Box'

export function BrandLogo() {
  const context = useContext(ContextContext)

  return (
    <Box height={[38, null, 54]} marginLeft={[2, 0]} marginRight={2}>
      <Link to={Paths.Index}>
        <Logo src={context?.sponsor === 'Msd' ? msdLogo : logo} alt="Logo" />
      </Link>
    </Box>
  )
}

const Logo = styled.img`
  width: auto;
  height: 100%;
  display: block;
`
