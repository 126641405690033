import { useAppSelector } from './useStoreHooks'

type License =
  | 'Base'
  | 'Lite'
  | 'Medication'
  | 'Safety'
  | 'International'
  | 'OfflinePdf'

export function useHasLicense(license: License) {
  const licenses = useAppSelector(
    (state) => state.user.profile.user.licenses ?? []
  )

  return licenses.includes(license)
}
