import styled from '@emotion/styled'
import ArrowUpIcon from '@mui/icons-material/ArrowUpward'
import Fab from '@mui/material/Fab'
import Tooltip from '@mui/material/Tooltip'
import { useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

function onScrollToTop() {
  window.scroll({
    top: 0,
    left: 0,
    behavior: 'smooth',
  })
}

export function ScrollTop() {
  const [isVisible, setIsVisible] = useState(false)

  const debouncedVisibility = useDebouncedCallback(() => {
    // show button only if we are not on top of the page
    if (window.scrollY > 100) {
      setIsVisible(true)
    } else {
      setIsVisible(false)
    }
  }, 600)

  useEffect(() => {
    window.addEventListener('scroll', debouncedVisibility)
    // Cleanup on unmount
    return () => {
      window.removeEventListener('scroll', debouncedVisibility)
    }
  }, [debouncedVisibility])

  return (
    isVisible && (
      <Container>
        <Tooltip title="Nach Oben" aria-label="Nach Oben">
          <Fab onClick={onScrollToTop} color="primary">
            <ArrowUpIcon />
          </Fab>
        </Tooltip>
      </Container>
    )
  )
}

const Container = styled.div`
  position: fixed;
  bottom: 25px;
  right: 25px;

  ${(props) => props.theme.down(1, props.theme.breakpoints)} {
    display: none;
  }
`
