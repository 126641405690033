import { gql } from '@apollo/client/core'
import { User } from '@austria-codex/types'

export type ProfileQueryResponse = {
  profile: {
    image: {
      fileName: string
      mimeType: string
      file: string
    } | null
    gender: {
      id: string
    } | null
    user: User
  }
}

export const PROFILE_QUERY = gql`
  query FetchProfile {
    profile {
      image {
        fileName
        mimeType
        file
      }
      gender {
        id
      }
      user {
        id
        profile
        customer
        type
        sectorId
        specialisationId
        licenses
        offlinePdfLink
      }
    }
  }
`
