import { FeatureEnum } from '@austria-codex/types'
import ArrowBackIcon from '@mui/icons-material/ArrowBackRounded'
import IconButton from '@mui/material/IconButton'
import { Link, Outlet } from 'react-router-dom'
import { ApplicationBar } from '../components/ApplicationBar/ApplicationBar'
import { Footer } from '../components/Footer/Footer'
import { Background } from '../components/Layout/Background'
import { Box, Flex } from '../components/Layout/Box'
import { Container } from '../components/Layout/Container'
import { Sheet } from '../components/Layout/Sheet'
import { Text } from '../components/Layout/Typography'
import { LinkTabItem, Tabs } from '../components/Navigation/Tabs'
import { useBundleFeature } from '../hooks/useBundleFeature'
import { useAppDispatch, useAppSelector } from '../hooks/useStoreHooks'
import { setBackUrl } from '../store/settings.store'
import { Paths } from '../utilities/path.constants'

export function SettingsPage() {
  const dispatch = useAppDispatch()
  const backUrl = useAppSelector((state) => state.settings.backUrl)
  const hasFooter = useBundleFeature(FeatureEnum.Footer)

  return (
    <>
      <ApplicationBar>
        <Flex alignItems="center">
          <Link
            to={backUrl ?? Paths.Index}
            onClick={() => dispatch(setBackUrl(null))}
          >
            <IconButton aria-label="Zurück">
              <ArrowBackIcon color="primary" />
            </IconButton>
          </Link>
          <Box ml={2}>
            <Text color="primary">Menü</Text>
          </Box>
        </Flex>
      </ApplicationBar>
      <Background pb={7} mb={-7} />
      <Container>
        <Box mt={3} mb={3}>
          <Sheet>
            <Tabs>
              <LinkTabItem to={Paths.Settings}>Konto</LinkTabItem>
              <LinkTabItem to={`${Paths.Settings}/module`}>Module</LinkTabItem>
              <LinkTabItem to={`${Paths.Settings}/zeichen`}>
                Zeichenerklärung
              </LinkTabItem>
              <LinkTabItem to={`${Paths.Settings}/fragen`}>
                Häufig gestellte Fragen
              </LinkTabItem>
            </Tabs>
            <Outlet />
          </Sheet>
        </Box>
      </Container>
      <Container>
        <Box as="footer" my={4} px={4}>
          {hasFooter && <Footer />}
        </Box>
      </Container>
    </>
  )
}
