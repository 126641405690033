import { Global, css } from '@emotion/react'
import styled from '@emotion/styled'
import Typography from '@mui/material/Typography'
import { useContext } from 'react'
import msdFontBold from '../../assets/fonts/Invention_W_Bd.woff?url'
import msdFontRegular from '../../assets/fonts/Invention_W_Rg.woff?url'
import logo from '../../assets/images/apoverlag-austriacodex.svg?url'
import MSDArrow from '../../assets/images/msd/msd-arrow.svg?react'
import msdBackground from '../../assets/images/msd/msd-background.png'
import msdLogo from '../../assets/images/msd/msd-logo-edge.png'
import msdLogoPlaceholder from '../../assets/images/msd/msd-placeholder.svg?url'
import { ContextContext } from '../../contexts/context'
import { TContext } from '../BaseDataProvider'
import { Box, Flex } from '../Layout/Box'
import { Text } from '../Layout/Typography'

export function LogoPlaceholder() {
  const context = useContext(ContextContext)

  return (
    <>
      <ApoverlagPlacehoder context={context} />
      {context?.sponsor === 'Msd' && <MSDPlaceholder />}
    </>
  )
}

const MSDStyles = css`
  @font-face {
    font-family: 'Invention';
    src: url(${msdFontRegular}) format('woff');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Invention';
    src: url(${msdFontBold}) format('woff');
    font-weight: 700;
    font-style: normal;
  }
`

const MSDButton = styled.a`
  background: white;
  border: none;
  border-radius: 5px;
  padding: 20px 32px;
  color: #100c27;
  font-size: 17px;
  font-weight: bold;
  font-family: 'Invention', serif;
  display: inline-block;

  text-decoration: none;
`

const MSDBackground = styled(Flex)`
  background-image: url('${msdBackground}');
  background-size: cover;
  background-position: center;
`

const MSDText = styled(Text)`
  font-family: 'Invention', serif;
  color: white;
`

export function MSDPlaceholder() {
  return (
    <>
      <Global styles={MSDStyles} />
      <Flex flexDirection={['column', null, 'row']}>
        <Flex
          flexBasis="50%"
          bg="#0F8479"
          p={[4, null, 5]}
          flexDirection="column"
        >
          <Box flex="column" flexGrow={1}>
            <MSDText fontSize={[28, null, 48]} pb={[3, null, 4]} as="div">
              Lust auf mehr?
            </MSDText>
            <MSDText fontSize={[18, null, 24]} pb={[3, 4]} as="div">
              Jetzt abtauchen und Fortbildungen, Studienupdates und
              Patient:innenmaterialien für Ihren medizinischen Alltag entdecken!
            </MSDText>
          </Box>
          <Box>
            <MSDButton
              target="_blank"
              href="https://www.msdconnect.at/login.xhtml"
            >
              MSD Connect
              <MSDArrow
                css={css`
                  margin-left: 16px;
                `}
              />
            </MSDButton>
          </Box>
        </Flex>
        <MSDBackground
          flexBasis="50%"
          position="relative"
          maxWidth={[null, null, 680]}
          minHeight={440}
        >
          <Box position="absolute" top="4" right="4">
            <img src={msdLogo} alt="MSD Logo" />
          </Box>
        </MSDBackground>
      </Flex>
    </>
  )
}

type ApoverlagPlacehoderProps = {
  context: TContext | null
}

function ApoverlagPlacehoder({ context }: ApoverlagPlacehoderProps) {
  return (
    <Flex
      height={context?.sponsor === 'Msd' ? 400 : 600}
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <img
        src={context?.sponsor === 'Msd' ? msdLogoPlaceholder : logo}
        alt="Logo"
        css={{
          margin: '32px',
          maxWidth: '420px',
          height: 'auto',
        }}
      />
      <Box m={3} maxWidth={420}>
        <Typography variant="subtitle1" align="center">
          Bitte verwenden Sie die Suchleiste oben, um Arzneispezialitäten und
          Wirkstoffe zu finden.
        </Typography>
      </Box>
    </Flex>
  )
}
