export const Paths = {
  Settings: '/menu',
  Alternatives: '/alternatives',
  Index: '/',
  Link: '/link',
  Login: '/login',
  Msd: '/msd',
  Redirect: '/redirect',
  Retail: '/handel',
  Safety: '/sicherheit',
  Unauthorized: '/unauthorized',
} as const
